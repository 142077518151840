import { Component, OnInit, ViewChild, ViewEncapsulation, HostListener, Renderer2 } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridApi, ICellRendererParams } from 'ag-grid-community';
import lodash from 'lodash';
import { FormGroup, FormControl } from '@angular/forms';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/core/services/common.service';
// import * as $ from 'jquery';
import moment from 'moment';
import { interval, Subject, Subscription, takeUntil, takeWhile, timer } from 'rxjs';
import { AppConfigService } from '../../../core/services/app-config.service';
import { RebarAuthService } from '../../../core/rebarauth/rebar.auth.service';
import { MsalService } from '@azure/msal-angular';
import { AppTimeoutService } from '../../../core/services/AppTimeoutService';

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AdminPageComponent implements OnInit {
  assetsAdmin = 'assets/img/admin/';
  filterIcon = this.assetsAdmin + 'filter_icon.svg';
  selectIcon = this.assetsAdmin + 'select_icon.svg';
  searchIcon = this.assetsAdmin + 'search_icon.svg';
  prevIcon = this.assetsAdmin + 'prev_icon.svg';
  nextIcon = this.assetsAdmin + 'next_icon.svg';
  clearIcon = this.assetsAdmin + 'clear_icon.svg';
  calendarIcon = this.assetsAdmin + 'calendar_icon.svg';
  exportIcon = this.assetsAdmin + 'export_icon.svg';
  gridCurrentPage: any = 0;
  gridTotalPages: any = 0;
  gridPaginationPageSize: number = 20;
  inputSearch: any = '';
  gridApi!: GridApi;
  submitDate!: Date;
  constants!: any;
  isFormStatusApplied: any = false;
  isNomineesMarketApplied: any = false;
  isMarketUnitsApplied: any = false;
  isModalOpen: boolean = false;
  isColumnModalOpen: boolean = false;
  isFilterModalOpen: boolean = false;
  filter1 = new FormControl();
  filter2 = new FormControl();
  filter3 = new FormControl();
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  currentusername: any = '';
  isAdmin = false;
  isNominee = false;
  isReviewer = false;
  userRole?: any;
  hideSearchCloseIcon = true;
  gridSortIcons = {
    sortAscending: '<img class="sortDescending" src="/assets/img/admin/arrow_downward.svg">',
    sortDescending: '<img class="sortDescending" src="/assets/img/admin/arrow_upward.svg">',
  };
  isEnterDateRangeLabelVisible: boolean = false;
  isSelectColumnReset: boolean = false;
  matDateRangeInput!: any;
  isSubmitDateInvalid: boolean = false;
  isGridFilterHasChangesApplied: boolean = false;
  isCalendarOpened: boolean = false;

  // For accessing the Grid's API
  // @ViewChild(AgGridAngular) agGrid!: any;
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  @ViewChild('popOver') public popover!: NgbPopover;
  @ViewChild('popOverSelectColumn') public popOverSelectColumn!: NgbPopover;


  // Each Column Definition results in one Column.
  public columnDefs!: any;

  // DefaultColDef sets props common to all Columns
  public defaultColDef!: ColDef;

  // Data that gets displayed in the grid
  // public rowData$!: Observable<any[]>;
  public rowData$!: any;
  public adminGridFiltersData!: any;
  public adminColumnsField!: any;
  public selectColumnSearchChange: any = '';
  public optionFormStatus: any = '';
  public formStatus = ['All Form Statuses'];
  public FormStatusCd: any;
  public NomineesMarket: any;
  public MarketUnits: any;
  public Certifications: any;
  public optionNomineesMarket: any = '';
  public optFormStatus: any = '';
  public prevFormStatus: any = [];
  public optNomineesMarket: any = '';
  public nomineesMarket = ["Nominee's Market"];
  public prevNomineesMarket: any = [];
  public optionAllMarketUnits: any = '';
  public optMarketUnits: any = '';
  public allMarketUnits = ['All Market Units'];
  public prevMarketUnits: any = [];
  public radioCertification: any = '';
  public optCertification: any = '';
  public certification = '0';
  public prevCertification: any = '';
  public submittedDate: any = '';
  public cancel: any = '';
  public submittedDateStart!: any;
  public prevSubmittedDateStart!: any;
  public submittedDateEnd!: any;
  public prevSubmittedDateEnd!: any;
  public optionSelectedColumnsField: any = [];
  public optionAllColumnsField: any = '';
  public optionColumnsFieldBackup: any = '';
  public gridParams!: any;
  public formStatusPlaceholder: any = "All Form Statuses";
  public nomineesMarketPlaceholder: any = "All Markets";
  public allMarketUnitsPlaceholder: any = "All Market Units";
  onNotHelpSupportClickSubscription: Subscription;
  private refreshSubscription!: Subscription;
  private unsubscribe$ = new Subject<void>();
  private retainPaginationflag: boolean = false;
  private paginationState: any;

  private readonly checkInterval = 1000; // Check every second
  private destroy$ = new Subject<void>();
  private readonly INACTIVITY_TIMEOUT = 60 * 60 * 1000; // 1 hour in milliseconds
  private readonly ACTIVITY_CHECK_INTERVAL = 5000; // 5 seconds in milliseconds
  private logoutKey = 'userLogout';
  configData: any;
  async ngOnInit(): Promise<void> {
    try {
      // var goToAdminPage =
      //   (location.hash.split('#/')[1] != 'admin' &&
      //     location.hash.split('#/')[1] == undefined) ||
      //   location.hash.split('#/')[1] == 'admin' ||
      //   location.hash.split('#/')[1] == '';
      // if (goToAdminPage) this.commonService.getAdminAccessState();

      //this.getAdminAccess();
      this.setupStorageListener();
      this.startTokenCheck();
      this.commonService.checkUserRole().subscribe((usr: string) => {
        if (usr === "Administrator" || usr === "Reviewer" || usr === "ReadOnly") {
          this.isAdmin = true;
        }

      });

      this.refreshSubscription = interval(10000)
        .pipe(
          takeUntil(this.unsubscribe$)
        )
        .subscribe(() => {
          this.commonService.getgridupdatedtimeflag().subscribe(
            async (result: any) => {
              if (result[0].RefreshFlag == 1) {
                console.log("updated grid");
                this.retainPaginationflag = true;
                this.capturePaginationState();
                this.appliedFilterAndRefreshedData();
                //  this.onGridFilterApplyReset('Apply');

                //this.inputSearch = "";
                this.restorePaginationState();
                if (this.inputSearch) {
                  this.onKeyupSearch();
                }

              }
            });

        });

    } catch (error) {
      console.log("error ngOnInit admin-page.component.ts: ", error);
    }
  }

  constructor(private rebarAuthService: RebarAuthService,
    public commonService: CommonService,
    private modalService: NgbModal, private renderer: Renderer2,
    private config: AppConfigService, private auth: MsalService, private apptimeoutService: AppTimeoutService
  ) {
    this.resetapplicationTimer();
    this.startapplicationActivityCheck();
    this.configData = config.getConfig();
    this.onNotHelpSupportClickSubscription = this.commonService.nothelpsupportButtonClicked$.subscribe(
      () => {
        if (this.popover.isOpen()) { this.popover.close(); }
        if (this.popOverSelectColumn.isOpen()) { this.popOverSelectColumn.close(); }
      }
    );
  }
 
  capturePaginationState() {
    this.paginationState = {
      currentPage: this.gridCurrentPage,
      pageSize: this.gridApi.paginationGetPageSize(),
      totalPages: this.gridApi.paginationGetTotalPages()
    };
  }

  restorePaginationState() {
    if (this.paginationState) {
      // this.gridTotalPages = this.paginationState.totalPages;
      this.gridTotalPages = Math.ceil(this.rowData$.length / this.gridPaginationPageSize);
      //this.gridCurrentPage = this.paginationState.currentPage;
      if (this.gridTotalPages > 0 && this.gridCurrentPage <= this.gridTotalPages) {

        if (this.paginationState.currentPage == 0) {
          this.gridApi.paginationGoToPage(0);
          this.gridCurrentPage = 1;
        }
        else {
          this.gridCurrentPage = this.paginationState.currentPage;
          this.gridApi.paginationGoToPage(this.gridCurrentPage - 1);
        }

      }
      else if (this.gridCurrentPage >= this.gridTotalPages) {
        //this.gridCurrentPage = (this.paginationState.currentPage == 0) ? 1 : this.paginationState.currentPage;

        this.gridApi.paginationGoToPage(this.gridTotalPages);
      }

      // this.gridApi.paginationGoToPage(this.paginationState.currentPage);
      // this.gridApi.paginationSetPageSize(this.paginationState.pageSize);
    }

  }
  async onGridReady(params: any) {
    try {
      this.constants = this.commonService.constants;
      this.defaultColDef = this.constants.adminDefaultColDef;
      this.submitDate = this.constants.adminFilterSubmitDate;
      params.api.showLoadingOverlay();
      const adminGridParam = {
        FormStatusCd: 'ALL',
        MarketCd: 'ALL',
        MUCd: 'ALL',
        CertCd: 'ALL',
        SubmitDateFROM: 'ALL',
        SubmitDateTO: 'ALL',
        AdminUser: await this.commonService.getCurrentUsername(),
        ColumnIds: ''
      };

      this.commonService.gridbatchprocessData(adminGridParam, this.configData.gridatchIndex, this.configData.gridbatchSize).subscribe(
        async (result: any) => {
          result.map((allValues: any, index: any) => {
            return lodash.forEach(allValues, function (value: any, key: any) {
              var convertedValue = moment(allValues[key]).format("DD-MMM-YYYY");

              allValues[key] = convertedValue == 'Invalid date' || !(String(key).toLowerCase().includes('date') || String(key).toLowerCase().includes('dt')) ? allValues[key] : convertedValue;
            });
          });

          this.rowData$ = await result;
          this.commonService.backUpAdminGridData.next(this.rowData$);
          // this.gridApi.setRowData(result);

          this.gridApi = params.api;
          this.gridTotalPages = Math.ceil(this.rowData$.length / this.gridPaginationPageSize);
          this.gridCurrentPage = (this.gridTotalPages > 0) ? 1 : 0;
          // this.columnDefs = this.commonService.adminColDef(this.rowData$);

          this.gridApi.sizeColumnsToFit();
          window.addEventListener('resize', function () {
            setTimeout(() => {
              // this.gridApi.sizeColumnsToFit();
              params.api.sizeColumnsToFit();
            });
          });

          this.gridApi.sizeColumnsToFit();
        },
        (error: any) => {
          console.log(
            'error admingriddata: ',
            error
          );
        }
      );
      this.getAdminGridFiltersData();
    } catch (error) {
      console.log("error onGridReady: ", error);
    }
  }



  filterDataInit(): void {
    try {
      this.initFormStatusFilter();
      this.initMarketFilter();
      this.initMarketUnitFilter();

      this.radioCertification = lodash.uniqBy(
        this.adminGridFiltersData.certificationType,
        'CertificationTypeDesc'
      );

      this.optionAllColumnsField = lodash.uniqBy(
        this.adminColumnsField,
        'OrderNbrGrid'
      );

      this.optionColumnsFieldBackup = lodash.uniqBy(
        this.adminColumnsField,
        'OrderNbrGrid'
      );

      if (!this.isFilterModalOpen) this.onSelectColumnsApplyReset('Apply');
    } catch (error) {
      console.log("error filterDataInit: ", error);
    }
  }

  // clearSelection(): void {
  //   this.gridApi.deselectAll();
  // }

  gridNextPage(): void {
    try {
      this.gridApi.paginationGoToNextPage();
      this.gridCurrentPage =
        this.gridCurrentPage == this.gridTotalPages
          ? this.gridTotalPages
          : this.gridCurrentPage + 1;
    } catch (error) {
      console.log("error gridNextPage: ", error);
    }
  }

  gridPreviousPage(): void {
    try {
      this.gridApi.paginationGoToPreviousPage();
      this.gridCurrentPage = this.gridCurrentPage == 1 ? 1 : (this.gridCurrentPage > 1) ? this.gridCurrentPage - 1 : 0;
    } catch (error) {
      console.log("error gridPreviousPage: ", error);
    }
  }

  onCurrentPageEnter(event: any): any {
    try {


      if (isNaN(Number(event.target.valueAsNumber))) {
        this.gridCurrentPage = (this.gridTotalPages > 0) ? 1 : 0;
      }
      else {
        this.gridCurrentPage = (this.gridTotalPages > 0) ? Number(event.target.valueAsNumber) : 0;
        if (Number(event.target.valueAsNumber) > 0) {
          this.gridCurrentPage = (this.gridTotalPages > 0) ? Number(event.target.valueAsNumber) : 0;
        }
        else {
          this.gridCurrentPage = (this.gridTotalPages > 0) ? 1 : 0;
        }
      }
      this.gridCurrentPage = this.gridCurrentPage > this.gridTotalPages ? this.gridTotalPages : this.gridCurrentPage;

      if (this.gridCurrentPage > 0) {
        this.gridApi.paginationGoToPage(this.gridCurrentPage - 1)
      } else {
        this.gridCurrentPage = 0;
        this.gridApi.paginationGoToPage(this.gridCurrentPage - 1)
      }

      if (this.gridCurrentPage.length != this.gridTotalPages.length) return false;
    } catch (error) {
      console.log("error onCurrentPageEnter: ", error);
    }
  }

  onKeyupSearch(): void {
    try {
      // this.inputSearch = event.target.value;
      this.gridApi.setQuickFilter(this.inputSearch);
      this.gridTotalPages = this.gridApi.paginationGetTotalPages();
      this.gridCurrentPage = (this.gridTotalPages > 0) ? 1 : 0;
    } catch (error) {
      console.log("error onKeyupSearch: ", error);
    }
  }

  onSearchFocusIn(): void {
    try {
      this.hideSearchCloseIcon = false;
      this.isColumnModalOpen = false;
      this.isFilterModalOpen = false;
    } catch (error) {
      console.log("error onSearchFocusIn: ", error);
    }
  }

  onSearchFocusOut(): void {
    try {
      setTimeout(() => {
        this.hideSearchCloseIcon = true;
      }, 250);
      this.isColumnModalOpen = false;
      this.isFilterModalOpen = false;
    } catch (error) {
      console.log("error onSearchFocusOut: ", error);
    }
  }

  clearSearchField(): void {
    try {
      this.hideSearchCloseIcon = true;
      this.inputSearch = '';
      this.gridApi.setQuickFilter(this.inputSearch);
      this.gridTotalPages = this.gridApi.paginationGetTotalPages();
      this.gridCurrentPage = (this.gridTotalPages > 0) ? 1 : 0;
    } catch (error) {
      console.log("error clearSearchField: ", error);
    }
  }

  onGridFilterApplyReset(button: any): void {
    try {
      switch (button) {
        case 'Apply':
          // if (
          //   this.formStatus != undefined ||
          //   this.nomineesMarket != undefined ||
          //   this.allMarketUnits != undefined ||
          //   this.certification != undefined
          //   ||
          //   this.submittedDateStart != undefined ||
          //   this.submittedDateEnd != undefined
          // ) {
          //   console.log('error you must select a date range: ');
          // }else{

          // }

          // try {
          //   console.log("error date2: ",this.submittedDateStart, ' - ', this.submittedDateEnd)
          //   console.log("error date1: ",this.formatSubmitDate(this.submittedDateStart._i), ' - ', this.formatSubmitDate(this.submittedDateEnd._i ?? 0))
          // } catch (error) {
          //   console.log('error: you must select a date range: ', error);
          // }
          // this.submittedDateStart = this.range.controls.start.value;
          // this.submittedDateEnd = this.range.controls.end.value;
          if (
            JSON.stringify(this.formStatus) ==
            JSON.stringify(['All Form Statuses'])
          ) {
            this.formStatus.forEach((element) => {
              // this.gridApi.setQuickFilter(element);
              this.formStatus = ['ALL'];
            });
          }

          if (
            JSON.stringify(this.nomineesMarket) ==
            JSON.stringify(["Nominee's Market"])
          ) {
            this.nomineesMarket.forEach((element) => {
              // this.gridApi.setQuickFilter(element);
              this.nomineesMarket = ['ALL'];
            });
          }

          if (
            JSON.stringify(this.allMarketUnits) ==
            JSON.stringify(['All Market Units'])
          ) {
            this.allMarketUnits.forEach((element) => {
              // this.gridApi.setQuickFilter(element);
              this.allMarketUnits = ['ALL'];
            });
          }

          this.prevCertification = this.certification;
          if (JSON.stringify(this.certification) == JSON.stringify('0')) {
            this.certification = 'ALL';
          }

          this.gridApi.showLoadingOverlay();
          this.adminPopovers('filter', 'Close');
          const adminGridParam = {
            FormStatusCd: this.formStatus.toString(),
            MarketCd: this.nomineesMarket.toString(),
            MUCd: this.allMarketUnits.toString(),
            CertCd: this.certification.toString(),
            SubmitDateFROM: this.submittedDateStart == undefined ? 'ALL' : this.formatSubmitDate(this.submittedDateStart._d),
            SubmitDateTO: this.submittedDateEnd == undefined ? 'ALL' : this.formatSubmitDate(this.submittedDateEnd._d),
            AdminUser: this.commonService.getCurrentUsername(),
            ColumnIds: ''
          };
          this.FormStatusCd = this.formStatus.toString();
          this.NomineesMarket = this.nomineesMarket.toString();
          this.MarketUnits = this.allMarketUnits.toString();
          this.Certifications = this.certification.toString();
          this.prevFormStatus = (this.formStatus.toString()).split(",");
          this.prevNomineesMarket = (this.nomineesMarket.toString()).split(",");
          this.prevMarketUnits = (this.allMarketUnits.toString()).split(",");
          this.prevSubmittedDateStart = this.submittedDateStart;
          this.prevSubmittedDateEnd = this.submittedDateEnd;

          this.commonService.gridbatchprocessData(adminGridParam, this.configData.gridatchIndex, this.configData.gridbatchSize).subscribe(
            async (result: any) => {
              result.map((allValues: any, index: any) => {
                return lodash.forEach(allValues, function (value, key) {
                  var convertedValue = moment(allValues[key]).format("DD-MMM-YYYY");

                  allValues[key] = convertedValue == 'Invalid date' || !(String(key).toLowerCase().includes('date') || String(key).toLowerCase().includes('dt')) ? allValues[key] : convertedValue;
                });
              });

              this.rowData$ = await result;
              this.columnDefs = this.commonService.adminColDef(this.rowData$);
              this.gridApi.paginationGoToPage(0);
              this.gridTotalPages = Math.ceil(this.rowData$.length / this.gridPaginationPageSize);
              this.gridCurrentPage = (this.gridTotalPages > 0) ? 1 : 0;
              this.gridApi.sizeColumnsToFit();
              await this.onSelectColumnsApplyReset('Apply');
            },
            (error: any) => {
              console.log(
                'error error admingriddata gridFilterApply: ',
                error
              );
            }
          );

          this.certification = this.certification == 'ALL' || this.certification == '0' ? '0' : this.certification;
          this.isGridFilterHasChangesApplied = this.formStatus.length != this.optionFormStatus.length || this.nomineesMarket.length != this.optionNomineesMarket.length || this.allMarketUnits.length != this.optionAllMarketUnits.length || this.certification != '0' ? true : false || (this.submittedDateStart != undefined && this.submittedDateEnd != undefined) ? true : false;
          // this.getAdminGridFiltersData();

          this.gridApi.onFilterChanged();
          this.gridApi.paginationGoToPage(0);
          this.gridTotalPages = Math.ceil(this.rowData$.length / this.gridPaginationPageSize);
          this.gridCurrentPage = (this.gridTotalPages > 0) ? 1 : 0;

          break;

        case 'Reset':
          this.formStatus = ['All Form Statuses'];
          this.nomineesMarket = ["Nominee's Market"];
          this.allMarketUnits = ['All Market Units'];
          this.certification = '0';
          // this.range.controls.start;
          this.submittedDateStart = undefined;
          this.submittedDateEnd = undefined;
          this.filterDataInit();
          this.isSubmitDateInvalid = false;
          this.isGridFilterHasChangesApplied = false;
          this.submitDateClicked = false;
          break;

        default:
          break;
      }
    } catch (error) {
      console.log(
        'error onGridFilterApplyReset:',
        error
      );
    }
  }

  appliedFilterAndRefreshedData() {
    if (
      JSON.stringify(this.formStatus) ==
      JSON.stringify(['All Form Statuses'])
    ) {
      this.formStatus.forEach((element) => {
        // this.gridApi.setQuickFilter(element);
        this.formStatus = ['ALL'];
      });
    }

    if (
      JSON.stringify(this.nomineesMarket) ==
      JSON.stringify(["Nominee's Market"])
    ) {
      this.nomineesMarket.forEach((element) => {
        // this.gridApi.setQuickFilter(element);
        this.nomineesMarket = ['ALL'];
      });
    }

    if (
      JSON.stringify(this.allMarketUnits) ==
      JSON.stringify(['All Market Units'])
    ) {
      this.allMarketUnits.forEach((element) => {
        // this.gridApi.setQuickFilter(element);
        this.allMarketUnits = ['ALL'];
      });
    }

    this.prevCertification = this.certification;
    if (JSON.stringify(this.certification) == JSON.stringify('0')) {
      this.certification = 'ALL';
    }

    // this.gridApi.showLoadingOverlay();
    this.adminPopovers('filter', 'Close');
    const adminGridParam = {
      FormStatusCd: this.formStatus.toString(),
      MarketCd: this.nomineesMarket.toString(),
      MUCd: this.allMarketUnits.toString(),
      CertCd: this.certification.toString(),
      SubmitDateFROM: this.submittedDateStart == undefined ? 'ALL' : this.formatSubmitDate(this.submittedDateStart._d),
      SubmitDateTO: this.submittedDateEnd == undefined ? 'ALL' : this.formatSubmitDate(this.submittedDateEnd._d),
      AdminUser: this.commonService.getCurrentUsername(),
      ColumnIds: ''
    };

    this.FormStatusCd = this.formStatus.toString();
    this.NomineesMarket = this.nomineesMarket.toString();
    this.MarketUnits = this.allMarketUnits.toString();
    this.Certifications = this.certification.toString();
    this.prevFormStatus = (this.formStatus.toString()).split(",");
    this.prevNomineesMarket = (this.nomineesMarket.toString()).split(",");
    this.prevMarketUnits = (this.allMarketUnits.toString()).split(",");
    this.prevSubmittedDateStart = this.submittedDateStart;
    this.prevSubmittedDateEnd = this.submittedDateEnd;

    this.commonService.gridbatchprocessData(adminGridParam, this.configData.gridatchIndex, this.configData.gridbatchSize).subscribe(
      async (result: any) => {
        result.map((allValues: any, index: any) => {
          return lodash.forEach(allValues, function (value, key) {
            var convertedValue = moment(allValues[key]).format("DD-MMM-YYYY");

            allValues[key] = convertedValue == 'Invalid date' || !(String(key).toLowerCase().includes('date') || String(key).toLowerCase().includes('dt')) ? allValues[key] : convertedValue;
          });
        });

        this.rowData$ = await result;
        this.columnDefs = this.commonService.adminColDef(this.rowData$);



        this.gridApi.sizeColumnsToFit();
        await this.onSelectColumnsApplyReset('Apply');
      },
      (error: any) => {
        console.log(
          'error error admingriddata gridFilterApply: ',
          error
        );
      }
    );

    this.certification = this.certification == 'ALL' || this.certification == '0' ? '0' : this.certification;
    this.isGridFilterHasChangesApplied = this.formStatus.length != this.optionFormStatus.length || this.nomineesMarket.length != this.optionNomineesMarket.length || this.allMarketUnits.length != this.optionAllMarketUnits.length || this.certification != '0' ? true : false || (this.submittedDateStart != undefined && this.submittedDateEnd != undefined) ? true : false;
    // this.getAdminGridFiltersData();

    this.gridApi.onFilterChanged();

    this.gridTotalPages = Math.ceil(this.rowData$.length / this.gridPaginationPageSize);

    // this.restorePaginationState();

  }
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    try {
      const target = event.target as HTMLElement;
      const targetClass = target.offsetParent?.className.toString();
      if (targetClass?.search('dropdown') == -1 && targetClass?.search('filter') == -1 && targetClass?.search('mat') == -1 && targetClass?.search('mdc') == -1 && targetClass?.search('radio') == -1 && targetClass?.search('cdk') == -1 && target.className?.search('popover') == -1) {
        if (this.popover.isOpen()) {
          this.adminPopovers('cancel', 'Close')
        }
      }
      const targets = event.target as HTMLElement;
      if (targets.tagName == "LABEL") {
        if (this.popOverSelectColumn.isOpen()) {
          event.preventDefault();
          event.stopPropagation();
        }
      }
      if (targetClass?.search('search') == -1 && targetClass?.search('select') == -1 && targetClass?.search('popover') == -1 && target.className?.search('popover') == -1) {
        if (this.popOverSelectColumn.isOpen()) {
          this.adminPopovers('selectColumns', 'Close');
          this.selectColumnSearchChange = '';
          this.onSelectColumnSearchChange(this.selectColumnSearchChange);
        }
      }
    } catch (error) {
      console.log("error onClick: ", error);
    }
  }

  public adminPopovers(which: any, button: any): void {
    try {
      if (button == 'Open') {
        switch (which) {
          case 'filter':
            if (!this.popover.isOpen()) {
              this.isFilterModalOpen = true;
              this.isColumnModalOpen = false;
              this.popOverSelectColumn.close();
              this.popover.open();

              const selectorSubmitDate = document.querySelector('.submitDatePurpleOutline .submitDateInvalid');
              if (selectorSubmitDate == undefined || selectorSubmitDate == null) {
                this.submittedDateStart = undefined;
                this.submittedDateEnd = undefined;
                this.isSubmitDateInvalid = false;
                this.submitDateClicked = false;
              }

              if (this.prevSubmittedDateStart != undefined || this.prevSubmittedDateEnd != undefined) {
                this.submittedDateStart = this.prevSubmittedDateStart;
                this.submittedDateEnd = this.prevSubmittedDateEnd;
              }
            }
            break;

          case 'selectColumns':
            if (!this.popOverSelectColumn.isOpen()) {
              this.isFilterModalOpen = false;
              this.isColumnModalOpen = true;
              this.popover.close();
              this.popOverSelectColumn.open();
              this.selectColumnSearchChange = '';
            }
            break;

          default:
            break;
        }
        //this.isModalOpen = true;
      } else {
        switch (which) {
          case 'filter':
          case 'selectColumns':
            this.isFilterModalOpen = false;
            this.isColumnModalOpen = false;
            this.popOverSelectColumn.close();
            this.popover.close();
            break;
          case 'cancel':
            if (this.prevFormStatus != "") {
              this.formStatus = [];
              this.optFormStatus = [];
              lodash.forEach(this.adminGridFiltersData.formStatus, (result: any) => {
                for (let j = 0; j < this.prevFormStatus.length; j++) {
                  if (result.FormStatusCd == this.prevFormStatus[j]) {
                    this.optFormStatus.push(result);
                  }
                }
              });
              lodash.forEach(this.optFormStatus, (result: any) => {
                this.formStatus.push(result.FormStatusCd);
              });
              this.filter1 = new FormControl(this.formStatus);
            }
            else {
              this.initFormStatusFilter();
            }
            if (this.prevNomineesMarket != "") {
              this.nomineesMarket = [];
              this.optNomineesMarket = [];
              lodash.forEach(this.adminGridFiltersData.market, (result: any) => {
                for (let j = 0; j < this.prevNomineesMarket.length; j++) {
                  if (result.MarketId == this.prevNomineesMarket[j]) {
                    this.optNomineesMarket.push(result);
                  }
                }
              });
              lodash.forEach(this.optNomineesMarket, (result: any) => {
                this.nomineesMarket.push(result.MarketId);
              });
              this.filter2 = new FormControl(this.nomineesMarket);
            }
            else {
              this.initMarketFilter();
            }
            if (this.prevMarketUnits != "") {
              this.allMarketUnits = [];
              this.optMarketUnits = [];
              lodash.forEach(this.adminGridFiltersData.mu, (result: any) => {
                for (let j = 0; j < this.prevMarketUnits.length; j++) {
                  if (result.MUId == this.prevMarketUnits[j]) {
                    this.optMarketUnits.push(result);
                  }
                }
              });
              lodash.forEach(this.optMarketUnits, (result: any) => {
                this.allMarketUnits.push(result.MUId);
              });
              this.filter3 = new FormControl(this.allMarketUnits);
            }
            else {
              this.initMarketUnitFilter();
            }
            if (this.prevCertification != '') {
              this.certification = this.prevCertification;
            }
            else {
              this.certification = '0';
            }
            if (this.prevSubmittedDateStart != undefined && this.prevSubmittedDateEnd != undefined) {
              this.submittedDateStart = this.prevSubmittedDateStart;
              this.submittedDateEnd = this.prevSubmittedDateEnd;
            }
            else {
              this.submittedDateStart = undefined;
              this.submittedDateEnd = undefined;
            }

            this.isFilterModalOpen = false;
            this.isColumnModalOpen = false;
            this.popOverSelectColumn.close();
            this.isGridFilterHasChangesApplied = this.formStatus.length != this.optionFormStatus.length || this.nomineesMarket.length != this.optionNomineesMarket.length || this.allMarketUnits.length != this.optionAllMarketUnits.length || this.certification != '0' ? true : false || (this.submittedDateStart != undefined && this.submittedDateEnd != undefined) ? true : false;
            this.popover.close();
            break;


          default:
            break;
        }
        //this.isModalOpen = false;
        //this.isGridFilterHasChangesApplied = false;
      }
    } catch (error) {
      console.log("error adminPopovers: ", error);
    }
  }

  onRadioClick(option: any): void {
    try {
      this.certification = option._value;
    } catch (error) {
      console.log("error onRadioClick: ", error);
    }
  }

  onFilterApply(which: any, button: any): void {
    try {
      if (button === 'Apply') {
        this.isGridFilterHasChangesApplied = this.formStatus.length != this.optionFormStatus.length || this.nomineesMarket.length != this.optionNomineesMarket.length || this.allMarketUnits.length != this.optionAllMarketUnits.length || this.certification != '0' ? true : false || (this.submittedDateStart != undefined && this.submittedDateEnd != undefined) ? true : false;
        switch (which) {
          case 'FormStatus':
            this.isFormStatusApplied =
              this.formStatus.length == 0 ||
                this.formStatus[0] == 'All Form Statuses'
                ? false
                : true;
            break;

          case 'NomineesMarket':
            this.isNomineesMarketApplied =
              this.nomineesMarket.length == 0 ||
                this.nomineesMarket[0] == "Nominee's Market"
                ? false
                : true;
            break;

          case 'MarketUnits':
            this.isMarketUnitsApplied =
              this.allMarketUnits.length == 0 ||
                this.allMarketUnits[0] == 'All Market Units'
                ? false
                : true;
            break;

          default:
            this.isFormStatusApplied = false;
            this.isNomineesMarketApplied = false;
            this.isMarketUnitsApplied = false;
            break;
        }
      } else {
        switch (which) {
          case 'FormStatus':
            this.isFormStatusApplied = false;
            this.initFormStatusFilter();
            break;

          case 'NomineesMarket':
            this.isNomineesMarketApplied = false;
            this.initMarketFilter();
            break;

          case 'MarketUnits':
            this.isMarketUnitsApplied = false;
            this.initMarketUnitFilter();
            break;

          default:
            this.isFormStatusApplied = false;
            this.isNomineesMarketApplied = false;
            this.isMarketUnitsApplied = false;
            break;
        }
      }

      if (this.formStatus.length == 0) this.formStatus = ['All Form Statuses'];

      if (this.nomineesMarket.length == 0) this.nomineesMarket = ["Nominee's Market"];

      if (this.allMarketUnits.length == 0) this.allMarketUnits = ['All Market Units'];
    } catch (error) {
      console.log("error onFilterApply: ", error);
    }
  }

  async getAdminGridFiltersData(): Promise<void> {
    try {
      var gridFiltersParam = { AdminUser: await this.commonService.getCurrentUsername() };
      this.commonService.getAdminGridFiltersData(gridFiltersParam).subscribe(
        async (result: any) => {
          this.adminGridFiltersData = await result;
          this.getColumnFieldsData();
        },
        (error: any) => {
          console.log('error getAdminGridFiltersData service: ', error);
        }
      );

    } catch (error) {
      console.log("error getAdminGridFiltersData: ", error);
    }
  }

  getColumnFieldsData(): void {
    try {
      this.commonService.getColumnFieldsData().subscribe(
        async (result: any) => {
          this.adminColumnsField = await result;
          this.adminColumnsField.forEach((element: any) => {
            if (element.CheckIndGrid) this.optionSelectedColumnsField.push(element);
          });
          this.filterDataInit();
        },
        (error: any) => {
          console.log('error getColumnFieldsData service: ', error);
        }
      );
    } catch (error) {
      console.log("error getColumnFieldsData: ", error);
    }
  }

  onSelectColumnSearchChange(change: any): void {
    try {
      const SelectColfilterLowerCase = change.toLowerCase();
      this.optionAllColumnsField.forEach((item: any) => {
        if (item.ColumnDisplayName.toLowerCase().includes(SelectColfilterLowerCase)) {
          item.display = 'block';
        } else {
          item.display = 'none';
        }
      });
      //if (change == null || change == '' || change == undefined) {
      //  this.optionAllColumnsField = this.optionColumnsFieldBackup;      
      //} else {
      //  let filtered_array = lodash.filter(
      //    this.optionAllColumnsField,
      //    function (o) {
      //      return o.ColumnDisplayName.toLowerCase().includes(change.toLowerCase());
      //    }
      //  );
      //  this.optionAllColumnsField = filtered_array;
      //}
    } catch (error) {
      console.log("error onSelectColumnSearchChange: ", error);
    }
  }

  openVerticallyCentered(content: any) {
    try {
      this.isModalOpen = true;
      this.modalService.open(content, { centered: true });
    } catch (error) {
      console.log("error openVerticallyCentered: ", error);
    }
  }

  closeVerticallyCentered() {
    try {
      this.isModalOpen = false;
      this.modalService.dismissAll(true);
    } catch (error) {
      console.log("error closeVerticallyCentered: ", error);
    }
  }

  closePopoverSelectColumns() {
    try {
      this.selectColumnSearchChange = '';
      this.optionAllColumnsField = this.optionColumnsFieldBackup;
    } catch (error) {
      console.log("error closePopoverSelectColumns: ", error);
    }
  }

  onSelectColumnsApplyReset(button: any): any {
    try {
      this.isSelectColumnReset = true;

      switch (button) {
        case 'Apply':
          var result1: any = this.optionAllColumnsField.filter((s: any) => {
            const selectedColumnNames = this.optionSelectedColumnsField.map((selectedItem: any) =>
              selectedItem.ColumnDisplayName
            );

            return selectedColumnNames.includes(s.ColumnDisplayName);
          });
          var result2
            : any = this.optionAllColumnsField.filter((s: any) => {
              const selectedColumnNames = this.optionSelectedColumnsField.map((selectedItem: any) =>
                selectedItem.ColumnDisplayName
              );

              return !selectedColumnNames.includes(s.ColumnDisplayName);
            });
          //var result1: any = this.optionAllColumnsField.filter((s: any) => JSON.stringify(JSON.stringify(this.optionSelectedColumnsField).split('ColumnDisplayName":"')).includes(s.ColumnDisplayName));
          //var result2: any = this.optionAllColumnsField.filter((s: any) => !JSON.stringify(JSON.stringify(this.optionSelectedColumnsField).split('ColumnDisplayName":"')).includes(s.ColumnDisplayName));

          result1 = result1.map((allValues: any) => {
            return {
              "ColumnId": allValues.ColumnId,
              "FieldName": allValues.FieldName,
              "ColumnDisplayName": allValues.ColumnDisplayName,
              "OrderNbrGrid": allValues.OrderNbrGrid,
              "CheckIndGrid": true,
              "ActiveStatus": allValues.ActiveStatus,
              "ForceIncludeInd": allValues.ForceIncludeInd,
              "OrderNbrExport": allValues.OrderNbrExport
            }
          });

          result2 = result2.map((allValues: any) => {

            return {
              "ColumnId": allValues.ColumnId,
              "FieldName": allValues.FieldName,
              "ColumnDisplayName": allValues.ColumnDisplayName,
              "OrderNbrGrid": allValues.OrderNbrGrid,
              "CheckIndGrid": false,
              "ActiveStatus": allValues.ActiveStatus,
              "ForceIncludeInd": allValues.ForceIncludeInd,
              "OrderNbrExport": allValues.OrderNbrExport
            }
          });

          var merged = [];
          merged.push(...result1);
          merged.push(...result2);

          this.optionAllColumnsField = lodash.orderBy(merged, 'OrderNbrGrid', 'asc');
          this.optionSelectedColumnsField.forEach((rowDataelement: any) => {
            this.columnDefs = {
              adminColDefs: [
                {
                  headerName: 'Nominee\'s Enterprise ID',
                  field: 'NomineeEID',
                  lockPosition: 'left',
                  cellClass: 'locked-col',
                  cellRenderer: function (a: ICellRendererParams) {
                    return `<a href="/#/${a.value}" style="color:#a100ff;" target="_blank">${a.value}</a>`;
                  },
                },
              ],
            };

            this.optionSelectedColumnsField.forEach(
              (optionSelectedColumnsElement: any) => {
                this.columnDefs.adminColDefs.push({
                  headerName: optionSelectedColumnsElement.ColumnDisplayName,
                  field: optionSelectedColumnsElement.FieldName,
                  orderNbrGrid: optionSelectedColumnsElement.OrderNbrGrid
                });
              }
            );
          });
          this.columnDefs.adminColDefs = lodash.uniqBy(
            this.columnDefs.adminColDefs,
            'headerName'
          );

          this.columnDefs = lodash.orderBy(
            this.columnDefs.adminColDefs,
            'orderNbrGrid', 'asc'
          );
          this.selectColumnSearchChange = '';
          //this.optionAllColumnsField = this.optionColumnsFieldBackup;
          this.adminPopovers('selectColumns', 'Close');
          break;

        case 'Reset':
          this.isSelectColumnReset = true;
          this.optionSelectedColumnsField = [];
          this.adminColumnsField.forEach((element: any) => {
            if (element.CheckIndGrid) this.optionSelectedColumnsField.push(element);
          });
          this.columnDefs = this.commonService.adminColDef(this.rowData$);
          this.filterDataInit();

          this.optionAllColumnsField = this.optionColumnsFieldBackup;
          this.selectColumnSearchChange = '';
          this.onSelectColumnSearchChange(this.selectColumnSearchChange);
          this.adminPopovers('selectColumns', 'Close');
          break;
        default:
          break;
      }
    } catch (error) {
      console.log("error onSelectColumnsApplyReset: ", error);
    }
  }

  onSelectColumnChange(value: any, event: any): void {
    try {

      if (event.target.checked) this.optionSelectedColumnsField.push(value);
      else this.optionSelectedColumnsField = this.optionSelectedColumnsField.filter((ColumnDisplayName: any) => ColumnDisplayName.ColumnDisplayName != event.target.defaultValue
      );

      this.optionSelectedColumnsField = lodash.uniqBy(
        this.optionSelectedColumnsField,
        'OrderNbrGrid'
      );


      // this.optionColumnsFieldBackup = lodash.uniqBy(
      //   this.optionColumnsFieldBackup,
      //   'OrderNbrGrid'
      // );

      // if (event.srcElement.checked) {
      //   this.optionColumnsFieldBackup.push(value);
      // } else {
      //   this.optionColumnsFieldBackup = this.optionColumnsFieldBackup.filter((ColumnDisplayName: any) => ColumnDisplayName !== value);
      // }
    } catch (error) {
      console.log("error onSelectColumnChange: ", error);
    }
  }

  onClickedOutside(e: any) {
    // (clickOutside)="onClickedOutside($event);"
    try {
      if (e.delegateTarget.__zone_symbol__scrollfalse == null) this.adminPopovers('filter', 'Close');
    } catch (error) {
      console.log(
        'error there is an error when clickedOutside:',
        e.delegateTarget.__zone_symbol__scrollfalse,
        error
      );
    }
  }

  async getCurrentUserRole() {
    try {
      this.currentusername = await this.commonService.getCurrentUsername();
      const paramEID = { EID: this.currentusername };
      this.commonService
        .getCurrentUserRole(paramEID)
        .subscribe(async (response: any) => {
          this.userRole = await response[0].RoleDesc;

          if (this.userRole) {
            var RoleDesc = await this.userRole;

            if (RoleDesc === 'Administrator') this.isAdmin = true;
            else if (RoleDesc === 'Nominee') this.isAdmin = false
            else if (RoleDesc === 'Reviewer') this.isAdmin = true;

            //  if (this.isAdmin) {
            //  this.commonService.isAdmin$.next(true);
            //  } else {
            //  this.commonService.isAdmin$.next(false);
            //this.router.navigate(['/unauthorized']);
            //}
          }
        });
    } catch (error) {
      console.log("error getCurrentUserRole: ", error);
    }
  }

  getAdminAccess(): any {
    try {
      this.commonService.hasAdminAccess$.subscribe(async (result: any) => {
        this.isAdmin = await result;
      });
    } catch (error) {
      console.log("error getAdminAccess: ", error);
    }
  }

  clickedSearchField(action: any): void {
    try {
      switch (action) {
        case 'clicked':
          this.hideSearchCloseIcon = false;
          break;

        default:
          this.hideSearchCloseIcon = true;
          break;
      }
    } catch (error) {
      console.log("error clickedSearchField: ", error);
    }
  }

  selectColumnDefaultChecked(item: any): any {
    return item.OrderNbrGrid == 1 || item.OrderNbrGrid == 2 || item.OrderNbrGrid == 4 || item.OrderNbrGrid == 5 || item.OrderNbrGrid == 3 || item.OrderNbrGrid == 9 || item.OrderNbrGrid == 10;
  }


  submitDateFocus(which: any): any {
    try {
      this.isSubmitDateInvalid = this.validateSubmitDate(this.submittedDateStart._i, this.submittedDateEnd._i);
    } catch (error) {
      console.log("error submitDateFocus: ", error);
    }
  }

  formatSubmitDate(date: any) {
    //old code when passed is _i not _d
    // function pad(d: any) { return String(d).length == 1 ? '0'.concat(d) : d; }
    // return pad(date.year) + '-' + pad(date.month + 1) + '-' + pad(date.date);

    return moment(date).format("YYYY-MM-DD");
  }

  // selectColumnApplyCheckedValues() {
  //   this.optionSelectedColumnsField
  //   return true;
  // }

  validateSubmitDate(startDate: any, endDate: any): any {
    var isInvalidStartEndDate = false;

    try {
      var currentTime = moment.utc(moment.now()).valueOf();
      isInvalidStartEndDate = (startDate > endDate || startDate == endDate) || (isNaN(startDate) || isNaN(endDate) || currentTime == startDate || currentTime == endDate) ? true : false;
      // var month = startDate.month > endDate.month;
      // var day = startDate.date > endDate.date;
      // var year = startDate.year > endDate.year;
      // isInvalidStartEndDate = startDate == undefined || endDate == undefined ? true : month || day || year;
    } catch (error) {
      console.log("error validateSubmitDate: ", error);
    }

    return isInvalidStartEndDate;
  }

  startDateChange: any;
  endDateChange: any;
  submitDateChange(whichDate: any, value: any): any {
    try {
      var selector = document.querySelector('.submitdate-title~div mat-form-field div[class*="invalid"]');
      // var dateSeparator = document.querySelector('.submitdaterange .submitDateInvalid .mat-date-range-input-separator');

      if (whichDate == 'start') {
        this.startDateChange = moment.utc(value).valueOf();
      } else if (whichDate == 'end') {
        this.endDateChange = moment.utc(value).valueOf();
      }

      this.isSubmitDateInvalid = this.validateSubmitDate(this.startDateChange, this.endDateChange);
    } catch (error) {
      console.log("error submitDateChange: ", error);
    }
  }

  initFormStatusFilter() {
    try {
      this.optionFormStatus = lodash.uniqBy(
        this.adminGridFiltersData.formStatus,
        'FormStatusDesc'
      );
      this.formStatus = [];
      lodash.forEach(this.optionFormStatus, (result: any) => {
        this.formStatus.push(result.FormStatusCd);
      });
      this.filter1 = new FormControl(this.formStatus);
    } catch (error) {
      console.log("error initFormStatusFilter: ", error);
    }
  }

  initMarketFilter() {
    try {
      this.optionNomineesMarket = lodash.uniqBy(
        this.adminGridFiltersData.market,
        'MarketDesc'
      );
      this.nomineesMarket = [];
      lodash.forEach(this.optionNomineesMarket, (result: any) => {
        this.nomineesMarket.push(result.MarketId);
      });
      this.filter2 = new FormControl(this.nomineesMarket);
    } catch (error) {
      console.log("error initMarketFilter: ", error);
    }
  }

  initMarketUnitFilter() {
    try {
      this.optionAllMarketUnits = lodash.uniqBy(
        this.adminGridFiltersData.mu,
        'MarketDesc'
      );
      this.allMarketUnits = [];
      lodash.forEach(this.optionAllMarketUnits, (result: any) => {
        this.allMarketUnits.push(result.MUId);
      });
      this.filter3 = new FormControl(this.allMarketUnits);
    } catch (error) {
      console.log("error initMarketUnitFilter: ", error);
    }
  }

  submitDateClicked: boolean = false;
  isFilterCalendarOpened(): void {
    try {
      const selector = document.querySelector(".mat-datepicker-popup");
      // var dateSeparator = document.querySelector('.submitdaterange .submitDateInvalid .mat-date-range-input-separator');

      if (selector != null) this.isCalendarOpened = true;
      else this.isCalendarOpened = false;

      if (document.querySelector('.cdk-overlay-container div') != null) this.isCalendarOpened = true
      else this.isCalendarOpened = false;

      this.submitDateChange('start', this.submittedDateStart);
      this.submitDateChange('end', this.submittedDateEnd);
      // if (dateSeparator != undefined && ((this.startDateChange == undefined || this.endDateChange == undefined) || (this.submittedDateStart == undefined || this.submittedDateEnd == undefined))) this.isSubmitDateInvalid = false;
    } catch (error) {
      console.log("error isFilterCalendarOpened: ", error);
    }
  }

  checkForStartEndSubmitDate(): void {
    try {
      if (this.submittedDateStart == undefined || this.submittedDateEnd == undefined) {
        this.submitDateClicked = true;
      } else {
        this.submitDateClicked = false;
      }

      if (this.submittedDateStart == undefined) {
        this.submittedDateStart = undefined;
        this.submittedDateEnd = undefined;
      }
    } catch (error) {
      console.log("error checkForStartEndSubmitDate: ", error);
    }
  }

  datePickerFilterYear(which: any) {
    const currentYear = moment().year();
    var minDate = moment([0, 0, 1]);
    var maxDate = moment([currentYear, 11, 31]);
    return which == 'min' ? minDate : maxDate;
  }


  submitDateEvents(which: any, dtStart: HTMLDivElement) {
    try {
      var dtStartFocused = document.querySelector('.mat-date-range-input-container[class*="focus"]');
      if (which == 'submitdaterange click' && dtStartFocused == undefined) dtStart.click(); this.submitDateClicked = true;
      if (which == 'submitdaterange focusout' && (this.submittedDateStart == undefined && this.submittedDateEnd == undefined)) {
        // dtStart.click(); 
        this.submittedDateStart = undefined;
        this.submittedDateEnd = undefined;
        this.isSubmitDateInvalid = false;
        // this.submitDateClicked = false;
      }
    } catch (error) {
      console.log("error submitDateEvents: ", error);
    }
  }

  ngOnDestroy() {
    try {
      this.onNotHelpSupportClickSubscription.unsubscribe();
      if (this.refreshSubscription) {
        this.refreshSubscription.unsubscribe();
      }
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
    } catch (error) {
      console.log("error ngOnDestroy: ", error);
    }
  }

//application Timeout logic start
  private resetapplicationTimer(): void {
    localStorage.setItem('lastActivityTime', Date.now().toString());
  }

  private startapplicationActivityCheck(): void {
    setInterval(() => {
      this.checkAppTimeoutActivity();
    }, this.ACTIVITY_CHECK_INTERVAL);
  }
  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:keypress', ['$event'])
  onUserInteraction(event: MouseEvent | KeyboardEvent): void {
    // Restart the timeout whenever there's user activity
    this.onUserActivity();
  }
  private checkAppTimeoutActivity(): void {
    const currentTime = Date.now();
    const lastActivityTime = parseInt(localStorage.getItem('lastActivityTime') || '0', 10);
    const timeSinceLastActivity = currentTime - lastActivityTime;
 
    if (timeSinceLastActivity >= this.INACTIVITY_TIMEOUT) {
      console.log('User inactive for too long. Perform timeout actions.');
      const logoutLink = document.getElementById('btnlogout');
      if (logoutLink) {
        this.renderer.selectRootElement(logoutLink).click();
      }
      this.rebarAuthService.applogout();
    }
  }

  public onUserActivity(): void {
    localStorage.setItem(this.logoutKey, 'false');
    // Call this method whenever there is user activity
    this.resetapplicationTimer();
  }
  private startTokenCheck() {
    setInterval(() => {
      const logoutkeyvalue = localStorage.getItem('logoutKey');
      if (logoutkeyvalue == 'true' || this.rebarAuthService.isLoggedOut()) {
        const accounts = this.auth.instance.getAllAccounts();
        this.auth.instance.setActiveAccount(accounts[0]);

        const logoutRequest = {
          account: this.auth.instance.getAllAccounts()[0],
        };
        this.rebarAuthService.logoutRedirect(logoutRequest);
      }
    }, this.checkInterval);
  }
  applicationLogout() {
    this.rebarAuthService.applogout();
  }
  private setupStorageListener(): void {
    // Listen for changes in localStorage (cross-window communication)
    window.addEventListener('storage', (event) => {
      if (event.key === 'logoutKey') {
        const logoutkeyvalue = localStorage.getItem('logoutKey');
        if (logoutkeyvalue == 'true') {
          this.rebarAuthService.applogout();
        }
      }
    });
  }
  //Application timeout logic ends
}


