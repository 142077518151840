import { Component, OnInit, ViewChild, ViewEncapsulation, Input, Injector } from '@angular/core';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { RebarAuthService } from 'src/app/core/rebarauth/rebar.auth.service';
import { GridApi } from 'ag-grid-community';
import { AdminPageComponent } from '../admin-page.component';

@Component({
  selector: 'app-eqadvalidationform',
  templateUrl: './eqadvalidationform.component.html',
  styleUrls: ['./eqadvalidationform.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class EqadvalidationformComponent implements OnInit {
  @Input() grid!: GridApi;
  @Input() NomineeEnterpriseId: string = "";
  @Input() NominatorPeopleKey: string = "";
  @Input() PeopleKeyQRL: string = "";
  @Input() RationaleNotes: string = "";
  @Input() HeldPriorCert: string = "";
  isModalOpen: boolean = false;
  public NomineeData: any = "";
  public currentUserName: any = "";
  public EQADExceptionApproved: any = "";
  public NewEQADFormPopup: any = null;

  @ViewChild('eqadValidationFormModal') public eqadValidationFormModal!: NgbPopover;

  ngOnInit(): void {
    this.rebarAuthService.refreshToken();
    this.currentUserName = this.commonService.getCurrentUsername();
  }

  constructor(private modalService: NgbModal, private rebarAuthService: RebarAuthService, private route: ActivatedRoute, private commonService: CommonService,
    private router: Router,private _injector: Injector) { }

  openVerticallyCenteredEQAD(content: any) {
    if (this.HeldPriorCert == "Yes") {
      this.createEQAD();
    }
    else {
      this.isModalOpen = true;
      if (this.NewEQADFormPopup) {
        this.NewEQADFormPopup.close();
      }
      this.NewEQADFormPopup = this.modalService.open(content, { windowClass: "myCustomModalClass", centered: true });
    }
  }

  closeVerticallyCenteredEQAD() {
    this.isModalOpen = false;
    //this.modalService.dismissAll(true);
    this.NewEQADFormPopup.close();
  }
  async createEQAD() {
    if (this.RationaleNotes.search(';') != -1) {
      this.RationaleNotes = this.RationaleNotes.replaceAll(';', '¥');
    }
    if (this.HeldPriorCert == "Yes") {
      this.EQADExceptionApproved = "";
    }
    else {
      this.EQADExceptionApproved = "Yes";
    }
    const saveparams = { NomineeEnterpriseId: this.NomineeEnterpriseId, NominatorPeopleKey: this.NominatorPeopleKey, CertificationId: 1, PeopleKeyQRL: this.PeopleKeyQRL, AdminUser: this.currentUserName, RationaleNotes: this.RationaleNotes, EQADExceptionApproved: this.EQADExceptionApproved };
    await this.commonService.saveNomineeDetails(saveparams).subscribe((response: any) => {
      this.NomineeData = response;
    });
    //this.grid.refreshCells();
    this.modalService.dismissAll(true);

    const _parent: AdminPageComponent = this._injector.get<AdminPageComponent>(AdminPageComponent);  
    _parent.onGridFilterApplyReset('Apply');
    const routeURL = `/${this.NomineeEnterpriseId}`;
    window.open(routeURL, '_blank');
  }


}
